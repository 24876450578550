<template>
  <div>
    404  
  </div>
</template>

<script>
export default {
  name: "login-request",
  components: {},
  data() {
    return {
     
    };
  },

  methods: {

  },
};
</script>


<style scoped>
</style>
